.myInfoTop {
  background-color: var(--blue);
  color: #ffffff;
  height: 10%;
  padding: 2vh 2vw;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.myInfoForm {
  padding: 0 2vw;
  box-sizing: border-box;
  text-align: center;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.myInfo {
  background-color: #fafafa;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}
.MuiStack-root {
  overflow: unset !important;
}
.selectContainer {
  margin-top: 15px;
  position: relative;
}
.selectLabel {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  position: absolute;
  left: 0;
  top: -5px;
  z-index: 10;
  -webkit-transform: translate(0, -1.5px) scale(0.75);
  -moz-transform: translate(0, -1.5px) scale(0.75);
  -ms-transform: translate(0, -1.5px) scale(0.75);
  transform: translate(0, -1.5px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
