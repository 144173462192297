.privacy-policy-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 10px;
    margin-left: 20px;
    align-items: center;
}

h1 {
    color: #333;
    text-align: center;
    font-size: 24px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

ol {
    padding-left: 20px;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }

    ol {
        padding-left: 10px;
    }
   
}