.refund-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 10px;
    color: #333;
    margin-left: 15px;
}

h1 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

strong {
    font-weight: bold;
}

ol {
    padding-left: 20px;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 24px;
    }

    p {
        font-size: 14px;
    }

    ol {
        padding-left: 10px;
    }
}